import React from "react";

import TextBox from "@components/TextBox";
import ListBox from "@components/ListBox";
import Memberships2 from "@components/Memberships2";
import BusinessBlock from "@components/BusinessBlock";
import Hero from "@components/Hero";
import Section from "@components/Section";
import Quote from "@components/Quote";

import * as styles from "./styles.module.css";



const PricePage2 = ({ 
  title, 
  backgroundImage,
  quote, 
  memberships2, 
  priceBlock, 
  intro,
  features,
  features2,
  businessBlock 
}) => {

  return (
    <>
    
      <Hero
        {...{
          title,
          backgroundImage,
          className: styles.hero,
        }}
      >
        <TextBox {...intro} className={styles.boxTwo} />
        <ListBox {...features} className={styles.boxOne} />
        <ListBox {...features2} className={styles.boxOne} />

      </Hero>

      <Section
        as="section"
        className={styles.container}
        backgroundColor={priceBlock.backgroundColor?.value}
        >
        <Memberships2 {...{memberships2, priceBlock}} />
      </Section>

      
      <Quote className={styles.quoteBox} {...quote} />
      <BusinessBlock {...businessBlock} />
    </>
  );
};

export default PricePage2;
