import React from "react";
import { graphql } from "gatsby";

import PricePage2 from "@components/PricePage2";

const Prices2 = ({ data }) => {
  const { pageData } = data;

  return <PricePage2 {...pageData} />;
};
 
export const query = graphql`
  query PricePageQuery2 {
    pageData: sanityPricePage2 {
      title 
      intro {
        ...SanityTextBoxFragment
      }
      features {
        title
        items
      }
      features2 {
        title
        items
      }
      
      priceBlock {
        prices {
        prisoverskrift
        prisinfo {
          info
          prices
          }
        _rawAbonnementsmodell2 
        }
        backgroundColor {
          value
        }
        overskrift
      }
      quote {
          author
          text
        }

      businessBlock {
        content {
          ...SanityTextBoxFragment
        }
        backgroundColor {
          value
        }
        memberships {
          ...SanityPriceBoxFragment
        }
      }
      backgroundImage {
        ...SanityBackgroundImageFragment
      }
    }
  }
`;

export default Prices2;
