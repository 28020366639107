
import React from "react";
import PropTypes from "prop-types";
import * as styles from "./styles.module.css";
import PortableText from "@components/PortableText";
import Section from "@components/Section";
import TextBox from "@components/TextBox";

const Memberships2 = ({ priceBlock }) => {
  return (
    <>
    <div className={styles}>
    <div className={styles.headingsContainer}>
      <h3 className={styles.textBox}>{priceBlock.overskrift}</h3> 
      </div>
      {priceBlock.prices.map(price => (
        <div className={styles.boxContainer}>
          <div className={styles.headingsContainer}>
            <h4>{price.prisoverskrift}</h4>
          </div>
          <table>
            {price.prisinfo.map(pris => (
              <tr>
                 <td>{pris.info}</td>
                 <td>{pris.prices}</td>
              </tr>
            ))}
              <tr>
              <td colSpan="2">
              {price._rawAbonnementsmodell2.map(abbonement => (
                  <PortableText className={styles.portable} content={abbonement} />
                ))}
                </td>
              </tr>
          </table>
        </div>
      ))}
    </div>
    <br />
    </>
  );
};

Memberships2.propTypes = {
  subtitle: PropTypes.string,
  items: PropTypes.array,
  prices: PropTypes.array
};

export default Memberships2;
